import 'angular-gettext';
import alerts, { AlertsService } from '../../../common/alerts/alerts.service';
import api, { ApiService } from '../../../common/api/api.service';
import preferencesOrganizations from '../organizations.service';
import users, { UsersService } from '../../../common/users/users.service';

class CreateAccountListController {
    saving: boolean;
    account: any;
    appName: string = process.env.APP_NAME;
    selectedUser: any;
    orgid: any;
    constructor(
        private $window: ng.IWindowService,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private alerts: AlertsService,
        private users: UsersService,
        private api: ApiService,
    ) {
        this.saving = false;
        this.account = {
            account_name: '',
            nav_id: null,
            person_key_account_id: '',
            org_id: this.orgid,
        };
        this.users.getUsers();
    }
    validFormData() {
        if (this.account.account_name && this.account.nav_id && this.account.person_key_account_id) {
            return true;
        }
        return false;
    }
    async save() {
        try {
            this.account.org_id = this.orgid;
            this.saving = true;
            let data = await this.users.createAccount(this.account);
            if (data) {
                await this.sendInvite(data.invited_to_email, data.account_list.id);
            }
            this.account = {
                account_name: '',
                nav_id: null,
                person_key_account_id: '',
                org_id: this.orgid,
            };
            let accountName = angular.element('#account_name');
            let navID = angular.element('#nav_id');
            accountName.removeClass('ng-touched');
            accountName.removeClass('ng-dirty');
            navID.removeClass('ng-touched');
            navID.removeClass('ng-dirty');
            const successfulMessage = this.gettextCatalog.getString('Create account successfully');
            this.alerts.addAlert(successfulMessage);
            this.saving = false;
        } catch (error) {
            const errorMessage = this.gettextCatalog.getString(`Create account error: ${error.message}`);
            const errorLevel = 'danger';
            this.alerts.addAlert(errorMessage, errorLevel, 3);
            this.saving = false;
            throw error;
        }
    }
    async sendInvite(email: string, accountListId: any) {
        return await this.api.post({
            url: `account_lists/${accountListId}/invites`,
            data: { recipient_email: email, invite_user_as: 'user', is_custom_policy: true },
            type: 'account_list_invites',
        });
    }
}

const CreateAccountList: ng.IComponentOptions = {
    template: require('./createAccountList.html'),
    controller: CreateAccountListController,
    bindings: {
        orgid: '<',
    },
};

export default angular
    .module('mpdx.preferences.organizations.createAccountList.component', [
        'gettext',
        alerts,
        api,
        preferencesOrganizations,
        users,
    ])
    .component('preferencesOrganizationsCreateAccountList', CreateAccountList).name;
