import { isNil } from 'lodash/fp';
import { StateParams, StateService } from '@uirouter/core';
import api, { ApiService } from '../../common/api/api.service';
import google, { GoogleService } from './google/google.service';
import help, { HelpService } from '../../common/help/help.service';
import integrations, { IntegrationsService } from './integrations.service';
import mailchimp, { MailchimpService } from './mailchimp/mailchimp.service';
import modal, { ModalService } from '../../common/modal/modal.service';
import prayerLetters, { PrayerLettersService } from './prayerLetters/prayerLetters.service';
import uiRouter from '@uirouter/angularjs';

class IntegrationPreferencesController {
    mailchimpAccStatus: string;
    saving: boolean;
    selectedTab: string;
    tabId: string;
    watcher: () => void;
    constructor(
        private $window: ng.IWindowService,
        $rootScope: ng.IRootScopeService,
        private $state: StateService,
        private $stateParams: StateParams,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private api: ApiService,
        private help: HelpService,
        private integrations: IntegrationsService,
        private modal: ModalService,
        private google: GoogleService,
        private mailchimp: MailchimpService,
        private prayerLetters: PrayerLettersService,
    ) {
        this.saving = false;
        this.tabId = '';

        this.mailchimpAccStatus = 'disabled';

        $window.openerCallback = this.reload;

        if (help.variables().HS_SETTINGS_SERVICES_SUGGESTIONS?.length) {
            const suggestionsArray = [];
            help.variables().HS_SETTINGS_SERVICES_SUGGESTIONS.forEach((i) => {
                suggestionsArray.push(this.gettextCatalog.getString(i));
            });
            help.suggest(suggestionsArray);
        }

        this.watcher = $rootScope.$on('accountListUpdated', () => {
            this.integrations.load();
        });
    }
    $onInit() {
        this.integrations.load();
        if (this.$stateParams.selectedTab) {
            this.setTab(this.$stateParams.selectedTab);
        }
    }
    $onChanges(data) {
        if (data.selectedTab) {
            this.setTab(this.selectedTab);
        }
    }
    $onDestroy() {
        this.watcher();
    }
    reload() {
        this.integrations.load();
    }
    sendToChalkline() {
        this.modal
            .confirm(
                this.gettextCatalog.getString(
                    'Would you like {{app_name}} to email Chalkline your newsletter list and open their order form in a new tab?',
                    {
                        app_name: process.env.APP_NAME,
                    },
                ),
            )
            .then(() => {
                this.integrations.sendToChalkline().then(() => {
                    this.$window.open('https://chalkline.org/order-navigators-mpd/', '_blank');
                });
            });
    }
    setTab(service) {
        if (!this.tabSelectable(service)) {
            return;
        }
        if ((service === '' || this.tabId === service) && !this.selectedTab) {
            this.tabId = '';
        } else {
            this.tabId = service;
        }
    }
    tabSelected(service) {
        return this.tabId === service;
    }
    tabSelectable(service) {
        return (this.selectedTab && this.selectedTab === service) || isNil(this.selectedTab);
    }
}

const Integrations = {
    controller: IntegrationPreferencesController,
    template: require('./integrations.html'),
    bindings: {
        selectedTab: '<',
        setup: '<',
        onSave: '&',
    },
};

export default angular
    .module('mpdx.preferences.integrations.component', [
        uiRouter,
        api,
        google,
        help,
        integrations,
        mailchimp,
        modal,
        prayerLetters,
    ])
    .component('preferencesIntegration', Integrations).name;
